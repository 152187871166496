.amplify-button--primary {
  --amplify-internal-button-background-color: rgb(171, 143, 128);
}
.amplify-button--primary:hover {
  --amplify-internal-button-background-color: rgb(137, 115, 101);
}
.amplify-button--primary:focus {
  --amplify-internal-button-background-color: rgb(137, 115, 101);
}
.amplify-button--primary:active {
  --amplify-internal-button-background-color: rgb(137, 115, 101);
}

.amplify-input {
  --amplify-components-input-border-color: rgb(171, 143, 128);
  --amplify-components-input-focus-border-color: rgb(171, 143, 128);
}

.amplify-button--link {
  --amplify-internal-button-color: rgb(171, 143, 128);
}

.amplify-button--link:hover {
  --amplify-internal-button-background-color: rgb(252, 252, 252);
  --amplify-internal-button-border-color: rgb(171, 143, 128);
  --amplify-internal-button-color: rgb(171, 143, 128);
}

[data-amplify-authenticator] [data-amplify-router] {
  background-color: none;
  box-shadow: none;
  border-color: var(--amplify-components-authenticator-router-border-color);
  border-width: var(--amplify-components-authenticator-router-border-width);
  border-style: none;
}

.amplify-heading--3 {
  text-align: center;
}

.amplify-heading--4 {
  text-align: center;
}
