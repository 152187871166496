body {
  margin: 0;
  font-family: 'T-Star Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'T-Star Pro', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@font-face {
  font-family: 'T-Star Pro';
  font-style: normal;
  src: local('T-Star Pro'), url(./fonts/t_star_pro/TSTAR-PRO-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'T-Star Pro';
  font-weight: bold;
  src: local('T-Star Pro'), url(./fonts/t_star_pro/TSTAR-PRO-Bold.otf) format('opentype');
}
@font-face {
  font-family: 'T-Star Pro';
  font-weight: 900;
  src: local('T-Star Pro'), url(./fonts/t_star_pro/TSTAR-PRO-Medium.otf) format('opentype');
}
